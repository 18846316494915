











import {defineComponent} from "@vue/composition-api";
import {getStateService} from "@f/services/Angular";
import ShareRewardByLink from './ShareRewardByLink.vue'
import ShareRewardByMail from './ShareRewardByMail.vue';

export default defineComponent({
  name: "ShareReward",
  props: {
    treecode: {type: String, required: true},
    treecodeUuid: {type: String, required: true},
  },
  components: {
    ShareRewardByLink,
    ShareRewardByMail
  },
  setup(props) {
    const useNativeShare = !!navigator.share;
    const stateService = getStateService();
    const state = "treecode";
    const giftUrl = stateService?.href(state, {treecode: props.treecode}, {absolute: true});
    return {
      giftUrl,
      useNativeShare,
    };
  },
});
