



























import {defineComponent, ref} from "@vue/composition-api";
import {isAuthenticated, onLoggedStatusChange} from "@f/services/Auth";
import LanguageSelector from "@f/components/Navbar/partials/LanguageSelector.vue";
import Logo from "@f/images/logo-dynamic.svg";
import GameCompleted from "../components/Game/Pages/GameCompleted/GameCompleted.vue";
import GameAuthentication from "@f/components/Game/Pages/GameAuthentication/GameAuthentication.vue";

export default defineComponent({
  name: "GameCompletePage",
  components: {
    GameAuthentication,
    Logo,
    LanguageSelector,
    GameCompleted
  },
  props: {
    treecode: String,
    rewardUuid: String,
  },
  setup() {
    const authenticated = ref(isAuthenticated());
    onLoggedStatusChange(() => (authenticated.value = isAuthenticated()), false);

    return {
      authenticated
    };
  },
});
