
































import {defineComponent, onMounted, ref} from "@vue/composition-api";
import {getStateService} from "@f/services/Angular";
import DotLoader from "@f/components/DotLoader.vue";
import Treecon from "@f/components/Treecons/Treecon.vue";
import ShareReward from "./shareReward/ShareReward.vue";
import LevelCompletion from "./../../Components/LevelCompletion.vue";
import SocialShare from "./SocialShare.vue";
import CommonHeaderGame from "./../../Layout/CommonHeaderGame.vue";
// import {fetchGameData, getTreecodeInfo} from "../../mockData";
import {fetchGameData, getTreecodeInfo} from "./../../gameData";

export default defineComponent({
  name: "GameCompleted",
  props: {
    treecode: {type: String, required: true},
    rewardUuid: String,
  },
  components: {
    DotLoader,
    Treecon,
    ShareReward,
    LevelCompletion,
    SocialShare,
    CommonHeaderGame
  },
  setup(props) {
    const gameInfo = ref();
    const gameInfoLoading = ref(true);
    const rewardloading = ref(true);
    const rewardTreecode = ref();
    const forestLink = ref();
    const stateService = getStateService();
    const christmasSkin = ref(false);
    const background = ref('');

    onMounted(() => {
      fetchGameData(props.treecode).then(async (_gameInfo) => {
        if (!_gameInfo?.gameCompleted) {
          stateService && stateService.go("game", {treecode: props.treecode});
        }
        if(_gameInfo.always_on !== undefined) {
          christmasSkin.value = !_gameInfo.always_on
        }
        if (christmasSkin.value) {
          background.value = require('@f/images/game/xmas-pattern-gifts-balls.png');
        }
        gameInfo.value = _gameInfo;
        gameInfoLoading.value = false;
        if (_gameInfo.triggerReward && props.rewardUuid) {
          await getTreecodeInfo({field: 'UUID', value: props.rewardUuid}).then((reward) => {
            rewardTreecode.value = reward?.code;
            if (!rewardTreecode.value) console.warn(`Couldn't resolve the generated reward treecode`)
          }).finally(() => rewardloading.value = false);
        } else {
          await getTreecodeInfo({field: 'CODE', value: props.treecode}).then((treecode) => {
            const forestSlug = treecode.event?.slug || treecode.trees[0].event?.slug;
            const userForestSlug = treecode.event?.slug || treecode.trees[0].event?.slug;
            forestLink.value = `${window.origin}${stateService && stateService.href('organization.event', {
              slug: userForestSlug,
              eventslug: forestSlug
            })}`;
          }).finally(() => rewardloading.value = false);
        }
      }).catch((error) => {
        console.log(error)
        stateService && stateService.go("game", {treecode: props.treecode});
      });
    });

    return {
      gameInfo,
      rewardTreecode,
      gameInfoLoading,
      rewardloading,
      forestLink,
      christmasSkin,
      background
    };
  },
});
