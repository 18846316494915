






















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Treecon from "@f/components/Treecons/Treecon.vue";
import { getModalService } from "@f/services/Angular";
import { isAuthenticated, onLoggedStatusChange } from "@f/services/Auth";
import CommonHeaderGame from "./../../Layout/CommonHeaderGame.vue";
import CommonCardGame from "../../Components/CommonCardGame.vue";

export default defineComponent({
  name: "GameAuthentication",
  components: {
    CommonHeaderGame,
    Treecon,
    CommonCardGame,
  },
  setup() {
    const openAuthenticationModal = () => {
      if (!isAuthenticated()) {
        getModalService()?.open({
          templateUrl: "login.html",
          easyClose: false,
          fitcontent: true,
        });
      }
    };
    return {
      openAuthenticationModal,
    };
  },
});
