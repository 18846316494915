import { GAME_COMPLETE, SHARE_GAME_REWARD_BY_MAIL } from "@f/graphql/mutations";
import { GAME_INFO, TREECODE_BY } from "@f/graphql/queries";
import { apiClient } from "@f/apollo";


export function fetchGameData(treecode: string) {
  return apiClient.query({
      query: GAME_INFO,
      variables:  {
        treecode: treecode,
      },
      fetchPolicy: 'network-only' 
    }).then(({data: { data }}) => data);
}


export function completeGame(treecodeUuid: string, correctAnswers: number) {
  return apiClient.mutate({
      mutation: GAME_COMPLETE,
      variables:  {
        input: {
          uuid: treecodeUuid,
          gameResult: correctAnswers
        }
      }
    }).then(( {data: { data }}) => data);
}

export async function getTreecodeInfo(variables: {field: string, value: string}) {
    const pollingLimit = 10;
    let pollingTries = 0;
    while(true) {
      const result = await apiClient.query({
        query: TREECODE_BY,
        variables: variables,
        fetchPolicy: 'network-only' 
      })
      if(result.data?.TreeCode_by) {
        return result.data.TreeCode_by;
      }
      pollingTries++;
      if(pollingTries === pollingLimit) {
        return null;
      }
      await sleep(500);
  }
}


export function shareRewardByMail(treecodeUuid: string, email: string) {
  return apiClient.mutate({
    mutation: SHARE_GAME_REWARD_BY_MAIL,
    variables:  {
      input: {
        uuid: treecodeUuid,
        email: email
      }
    }
  });
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));