




























import {defineComponent, onMounted, ref} from "@vue/composition-api";
import {isAuthenticated, onLoggedStatusChange} from "@f/services/Auth";
import GameAuthentication from "@f/components/Game/Pages/GameAuthentication/GameAuthentication.vue";
import LanguageSelector from "@f/components/Navbar/partials/LanguageSelector.vue";
import Logo from "@f/images/logo-dynamic.svg";
import Game from "@f/components/Game/Game.vue";
import {getStateService} from "@f/services/Angular";

export default defineComponent({
  name: "GamePage",
  components: {
    Game,
    Logo,
    LanguageSelector,
    GameAuthentication,
  },
  props: {
    treecode: String,
  },
  setup(props) {
    const authenticated = ref(isAuthenticated());
    const stateService = getStateService();
    onLoggedStatusChange((logged) => (authenticated.value = logged), false);
    onMounted(() => {
      if (!props.treecode) {
        stateService && stateService.go("index");
      }
    })
    return {
      authenticated
    };
  },
});
