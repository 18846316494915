































































import {defineComponent, onMounted, ref} from "@vue/composition-api";
import {getStateService} from "@f/services/Angular";
import DotLoader from "@f/components/DotLoader.vue";
import {GameStarter, GameStepper} from "./Pages";
import GameExceptions, {GameException} from "./Pages/Exceptions/GameExceptions.vue";
import {CO2BenefitQuestion, CountriesQuestion, IntruderQuestion, MulchQuestion, PuzzleGame,} from "./Steps";
import {DataEventGameAnswer, DataEventGameStart} from "@f/services/TagManager/events";
// import {completeGame, fetchGameData, getTreecodeInfo} from "./mockData";
import {completeGame, fetchGameData, getTreecodeInfo} from "./gameData";

export default defineComponent({
  name: "Game",
  components: {
    DotLoader,
    GameExceptions,
    GameStarter,
    GameStepper,
    CO2BenefitQuestion,
    MulchQuestion,
    CountriesQuestion,
    IntruderQuestion,
    PuzzleGame,
  },
  props: {
    treecode: {type: String, required: true},
  },
  methods: {
    isExpired(expireTimestamp: number) {
      const expireDate = new Date(expireTimestamp);
      const today = new Date();
      return expireDate < today;
    },
  },
  setup: (props) => {
    const correctAnswers = ref(0);
    const canGoForward = ref(false);
    const gameInfo = ref();
    const gameInfoisLoading = ref(true);
    const gameCompleted = ref(false);
    const gameStarted = ref(false);
    const treecodeUuid = ref();
    const treecodeOwnerName = ref();
    const gameException = ref();
    const stateService = getStateService();
    const christmasSkin = ref(false);
    const background = ref('');

    const startGame = () => {
      gameStarted.value = true;
      DataEventGameStart();
    };

    const onQuestionComplete = (result: boolean) => {
      if (result) {
        correctAnswers.value++;
      }
      if (!gameCompleted.value) {
        canGoForward.value = true;
      }
    };

    const nextStep = (currentStep: number) => {
      DataEventGameAnswer(currentStep);
      canGoForward.value = false;
    };

    const onGameComplete = () => {
      gameCompleted.value = true;
      if (treecodeUuid.value) {
        completeGame(treecodeUuid.value, correctAnswers.value)
            .then((result) => {
              result &&
              stateService &&
              stateService.go("gameComplete", {
                treecode: props.treecode,
                rewardUuid: result.rewardUuid,
              });
            })
            .catch((errorMessage) => {
              errorMessage = errorMessage.toString();
              if (
                  errorMessage.includes(
                      `User can't complete the game because hasn't redeemed treecode`
                  )
              ) {
                gameException.value = GameException.UserCantCompleteGame;
              } else {
                console.error(errorMessage);
              }
            });
      }
    };

    onMounted(() => {
      gameInfoisLoading.value = true;
      fetchGameData(props.treecode)
          .then((_gameInfo) => {
            if (!_gameInfo.gameEnabled) {
              stateService && stateService.go("treecode", {treecode: props.treecode});
            }
            if(_gameInfo.always_on !== undefined) {
              christmasSkin.value = !_gameInfo.always_on
            }
            if (christmasSkin.value) {
              background.value = require('@f/images/game/xmas-pattern-gifts-balls.png');
            }
            gameInfo.value = _gameInfo;
          })
          .catch((errorMessage) => {
            errorMessage = errorMessage.toString();
            if (errorMessage.includes(`TreeCode hasn't a game`)) {
              gameException.value = GameException.NotAGame;
            } else {
              console.error(errorMessage);
              gameException.value = GameException.Generic;
            }
          })
          .finally(() => (gameInfoisLoading.value = false));

      getTreecodeInfo({field: "CODE", value: props.treecode}).then((treecodeInfo) => {
        treecodeUuid.value = treecodeInfo?.uuid;
        treecodeOwnerName.value = treecodeInfo?.owner?.title;
      });
    });
    return {
      canGoForward,
      onQuestionComplete,
      nextStep,
      gameCompleted,
      correctAnswers,
      gameInfo,
      gameInfoisLoading,
      gameException,
      gameStarted,
      treecodeOwnerName,
      startGame,
      onGameComplete,
      GameException,
      christmasSkin,
      background
    };
  },
});
